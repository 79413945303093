import React, { useRef , useState} from 'react';
import '../App.css';
import Header from './header';
import Footer from './footer';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useForm } from "react-hook-form"
import emailjs from '@emailjs/browser';
// import aboutusimg from '../assets/img/aboutus.png'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Home() {
    const form = useRef();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm()

    const onSubmit = async(data) => {

        // console.log(data)
        setIsSubmitted(true);
        setIsDisabled(true);
        await emailjs
        .sendForm('service_9zsgojp', 'template_1nmcwbf', form.current, {
          publicKey: 'S2ufnPOquFARUo3v_',
        })
        .then(
          async() => {
            await emailjs
        .sendForm('service_9zsgojp', 'template_o3otf6q', form.current, {
          publicKey: 'S2ufnPOquFARUo3v_',
        })
        .then(
          () => {
            setIsDisabled(false);
            toast.success("Your request to connect with us  has been sent successfully!");
          },
          (error) => {
            console.log('FAILED...', error);
            setIsDisabled(false);
            toast.error("Oops! Form submition has been failed! Please try again later.");
          },
        );

          },
          (error) => {
            setIsDisabled(false);
            console.log('FAILED...', error);
          },
        );
               //   await transporter.sendMail(options);
          
    }
    const changeInput = ()=>{
        setIsSubmitted(false)
    }

    return (
        <>
        <ToastContainer />
           <div className='sticky-top'> <Header /></div>
            {/* <!-- banner sec --> */}
            <section className="banner-sec">
                <div className="containe-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <Swiper
                                // install Swiper modules
                                modules={[Navigation, Pagination, A11y, Autoplay]}
                                spaceBetween={50}
                                slidesPerView={1}
                                autoplay={{
                                    delay: 8000,
                                    disableOnInteraction: false,
                                }}
                                //   navigation
                                loop
                                Autoplay
                                pagination={{ clickable: true }}
                                onSwiper={(swiper) => { }}
                                onSlideChange={() => { }}
                            >
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <div className="swiper-content">
                                            <div className="ms-5">
                                                <h2 className='fw-light'>Our Offerings</h2>
                                                <h3 className='mb-64'>Crafting exceptional digital products and immersive experiences is our forte, and we excel in ensuring they reach and resonate with the ideal audience, amplifying their impact.</h3>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide> <div className="swiper-slide">
                                    <div className="swiper-content">
                                        <div className="ms-5">
                                            <h2>About Us</h2>
                                            <h3 className='mb-64'>At Solutionly, we're fueled by our passion for fostering business success in the digital domain. Our dedicated team of creative minds work together to ensure a process that caters to both user and business requirements.</h3>
                                        </div>
                                    </div>
                                </div></SwiperSlide>
                                <SwiperSlide>  <div className="swiper-slide">
                                    <div className="swiper-content">
                                        <div className="ms-5">
                                            <h2>User Centric Solutions</h2>
                                            <h3 className='mb-64'>Our priority is crafting user-centric products to drive business growth by aligning with customer needs and preferences, fostering lasting engagement and success.</h3>
                                        </div>
                                    </div>
                                </div></SwiperSlide>
                                <HashLink className="btn-1 td-none mt-3 ms-3 py-2" to="/#contactus">Get in Touch</HashLink>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- design sec --> */}
            <section className="design-sec bg-grey" id="desc">
                <div className="container-fluid px-lg-0">
                    <div className="px-lg-3  mx-4">
                        <h1 className='mb-0 pb-0'>We <span className="text-violet">design</span> distinctive interactive experiences to meet business objectives.</h1>
                       
                    </div>
                    </div>
                    </section>
            {/* about ssection  */}
            <section id='aboutuss'></section>
            <section className='aboutus-sec pt-0' id='aboutus'>
                <div className='bg-white about-padding'>
                <div className='container'>
                    <div className='text-content'>
                      <h2>About Us</h2>
                      <p>We blend creativity with strategy, ensuring every aspect of our services aligns with your brand goals. Our dedicated team collaborates closely with you to understand your vision and deliver tailor-made solutions that drive tangible results.</p>
                    </div>
                    <div className='d-none d-lg-block'>
                    <div className='row align-items-center mt-5'>
                        <div className='col-lg-3'>
                         <div>
                         <p className='fw-bold text mb-0'>1. Research</p>
                         <span className='text'>Research is important because it ensures that products meet the needs of users, leading to higher satisfaction and business success.</span>
                         </div>
                         <div className='mt-aboutus'>
                         <p className='fw-bold text mb-0'>4. Testing</p>
                         <span className='text'>User Testing validates the effectiveness of design solutions, providing valuable insights for refining products to better meet user needs and achieve business objectives.</span>
                         </div>
                        </div>
                        <div className='col-lg-6 text-center'>
                         <img src={require("../assets/img/aboutus.png")} width="500px" className='img-fluid' alt='aboutus-img'/>
                        </div>
                        <div className='col-lg-3'>
                         <div>
                         <p className='fw-bold text mb-0'>2. IDEAS</p>
                         <span className='text'>It facilitates the generation of creative solutions that meet user needs and propel business advancement.</span>
                         </div>
                         <div className='mt-aboutus'>
                         <p className='fw-bold text mb-0'>3. DESIGN</p>
                         <span className='text'>Designing enables the creation of innovative solutions that address user pain points and enhance overall user experience, and helps in making user centric products..</span>
                         </div>
                        </div>
                    </div>
                    </div>
                    <div className='row d-block d-lg-none'>
                    <div className='col-12 pt-4'>
                        <div className='mb-3'>
                        <img src={require("../assets/img/aboutus.png")} className='img-fluid' alt='aboutus-img'/>
                        </div>
                         <div className='mb-3'>
                         <p className='fw-bold text'>1. Research</p>
                         <span className='text'>Research is important because it ensures that products meet the needs of users, leading to higher satisfaction and business success.</span>
                         </div>
                         <div className='mb-3'>
                         <p className='fw-bold text'>2. IDEAS</p>
                         <span className='text'>It facilitates the generation of creative solutions that meet user needs and propel business advancement.</span>
                         </div>
                         <div className='mb-3'>
                         <p className='fw-bold text'>3. DESIGN</p>
                         <span className='text'>Designing enables the creation of innovative solutions that address user pain points and enhance overall user experience, and helps in making user centric products..</span>
                         </div>
                         <div>
                         <p className='fw-bold text'>4. Testing</p>
                         <span className='text'>User Testing validates the effectiveness of design solutions, providing valuable insights for refining products to better meet user needs and achieve business objectives.</span>
                         </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            <section className="design-sec position-relative overflow-x-hidden" id="services">
                <div className='bg-white py-5'>
                <div className="container-fluid px-lg-0">
                <div className="container">
                    
                    <h2 className="text-center">Our Services</h2>
                        <ul className="nav nav-pills text-center justify-content-center align-items-center pt-5 pb-4" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">UX Design</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">UI Design</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Web/App 
                                    Development</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#pills-disabled" type="button" role="tab" aria-controls="pills-disabled" aria-selected="false">Digital
                                    Marketing</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-fifth-tab" data-bs-toggle="pill" data-bs-target="#pills-fifth" type="button" role="tab" aria-controls="pills-fifth" aria-selected="false">Graphic Design</button>
                            </li>
                        </ul>
                        </div>
                        <div className='fluid-border'></div>
                <div className='container'>
                <div className="tab-content pt-5 " id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <ul className="list-item">
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>User Research</li>
                                            <li>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                                </svg>Persona Development</li>
                                            <li>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                                </svg>Usability Testing</li>
                                            <li>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                                </svg>Accessibility</li>
                                            <li>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                                </svg>Heuristic Evaluation</li>
                                            <li>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                                </svg>IA Validation</li>
                                            <li>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                                </svg>Wireframing</li>
                                            <li>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                                </svg>Concept Design</li>
                                            <li>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                                </svg>User Journey Mapping</li>
                                            <li>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                                </svg>Design Validation</li>
                                            <li>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                                </svg>POCs & MVPs/ Startups</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="text-center pt-4 pt-lg-0">
                                            <img src={require("../assets/img/ux-img.png")} className="img-fluid" alt="ux-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <ul className="list-item">
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Visual Design</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Interaction Design</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Rapid Prototyping</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Design Systems</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Content Styling</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="text-center pt-4 pt-lg-0">
                                            <img src={require("../assets/img/ui-img.png")} className="img-fluid" alt="ui-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <ul className="list-item">
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Website Development</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Word Press Development </li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>App Development</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Quality Assurance</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>User Acceptance Testing</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>IT Consulting</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="text-center pt-4 pt-lg-0">
                                            <img src={require("../assets/img/web-img.png")} className="img-fluid" alt="web-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-disabled" role="tabpanel" aria-labelledby="pills-disabled-tab" tabindex="0">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <ul className="list-item">
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Social Media Marketing</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Search Engine Optimization </li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Email Marketing</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Content Marketing</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Pay Per Click Advertising</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Online Reputation Management</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Campaigns Management</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="text-center pt-4 pt-lg-0">
                                            <img src={require("../assets/img/digital-img.png")} className="img-fluid" alt="dm-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-fifth" role="tabpanel" aria-labelledby="pills-fifth-tab" tabindex="0">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <ul className="list-item">
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Logo design</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Illustration </li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Print Design</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Branding & Identity Design</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Packaging Design</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Icon Design</li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg>Infographic Design</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="text-center pt-4 pt-lg-0">
                                            <img src={require("../assets/img/graphic-img.png")} className="img-fluid" alt="graphic-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>
                </div>
                        
                </div>
                <div className='fluid-border-bottom'></div>
                <div className="text-center py-5 mt-5">
                            <HashLink className="btn-2 td-none" to="/#contactus">Lets Connect</HashLink>
                        </div>
                </div>
            </section>
            {/* <!-- industries sec --> */}
            <section className="industry-sec" id="industries">
                <div className="bg-white py-5">
                    <div className="container">
                        <div className="industry-textbox">
                            <h2>Industries</h2>
                            <p>Our proficiency spans across multiple sectors, enabling us to effectively enhance sales for businesses.</p>
                        </div>
                        <div className="row pt-5 p-xl-0">
                            <div className="col-md-6 col-lg-4 text-center text-lg-start">
                                <img src={require("../assets/img/industries/icon1.png")} className="img-fluid mb-4" alt="icon1" />
                                <h5 class="mb-4">E-commerce.</h5>
                                <p className='para'>Refines online shopping experiences, boosting sales by making product discovery, selection, and checkout seamless.</p>
                            </div>
                            <div className="col-md-6 col-lg-4 text-center text-lg-start pt-4 pt-md-0">
                                <img src={require("../assets/img/industries/icon2.png")} className="img-fluid mb-4" alt="icon1" />
                                <h5 class="mb-4">Fintech</h5>
                                <p className='para'>Simplifies financial processes, ensuring user-friendly interfaces for banking, investing, and budgeting, fostering trust and usability.</p>
                            </div>
                            <div className="col-md-6 col-lg-4 text-center text-lg-start pt-4 pt-lg-0">
                                <img src={require("../assets/img/industries/icon3.png")} className="img-fluid mb-4" alt="icon1" />
                                <h5 class="mb-4">Healthcare</h5>
                                <p className='para'>Improves patient interactions with digital health tools, enhancing access to medical information and facilitating communication with healthcare providers for better care management.</p>
                            </div>
                            <div className="col-md-6 col-lg-4 text-center text-lg-start pt-4">
                                <img src={require("../assets/img/industries/icon4.png")} className="img-fluid mb-4" alt="icon1" />
                                <h5 class="mb-4">SAAS</h5>
                                <p className='para'>Optimizes software usability, increasing user adoption and retention by delivering intuitive interfaces and efficient workflows. </p>
                            </div>
                            <div className="col-md-6 col-lg-4 text-center text-lg-start pt-4">
                                <img src={require("../assets/img/industries/icon5.png")} className="img-fluid mb-4" alt="icon1" />
                                <h5 class="mb-4">Logistics</h5>
                                <p className='para'>Streamlines logistics operations by creating user-friendly interfaces for tracking shipments, managing inventory, and coordinating deliveries, improving efficiency and customer service.</p>
                            </div>
                            <div className="col-md-6 col-lg-4 text-center text-lg-start pt-4">
                                <img src={require("../assets/img/industries/icon6.png")} className="img-fluid mb-4" alt="icon1" />
                                <h5 class="mb-4">Travel & Hospitality</h5>
                                <p className='para'>Enhances the booking process, on-site experiences for travellers, providing personalized recommendations, easy navigation, and seamless interactions with accommodation and transportation services.</p>
                            </div>
                            <div className="col-md-6 col-lg-4 text-center text-lg-start pt-4">
                                <img src={require("../assets/img/industries/tdesign_education.png")} className="img-fluid mb-4" alt="icon1" />
                                <h5 class="mb-4">Edtech</h5>
                                <p className='para'>Improves the accessibility and effectiveness of educational platforms, making learning resources engaging, interactive, and tailored to individual student needs.</p>
                            </div>
                            <div className="col-md-6 col-lg-4 text-center text-lg-start pt-4">
                                <img src={require("../assets/img/industries/streamline_insurance-hand.png")} className="img-fluid mb-4" alt="icon1" />
                                <h5 class="mb-4">Insurance</h5>
                                <p className='para'>UX design simplifies policy management, claims processing, and customer service interactions, fostering trust and loyalty among policyholders.</p>
                            </div>
                            <div className="col-md-6 col-lg-4 text-center text-lg-start pt-4">
                                <img src={require("../assets/img/industries/grommet-icons_multimedia.png")} className="img-fluid mb-4" alt="icon1" />
                                <h5 class="mb-4">Media</h5>
                                <p className='para'>Enriches content discovery and consumption experiences, driving audience engagement and retention across digital media platforms</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- why solutionly sec --> */}
            <section className="why-solution" id="why-solution">
                <div className="bg-white py-5">
                    <div className="container">
                        <div className="text-center">
                            <h2>Why Solutionly?</h2>
                            <p>We blend creativity with strategy, ensuring every aspect of our services aligns with your brand goals. Our dedicated team collaborates closely with you to understand your vision and deliver tailor-made solutions that drive tangible results.</p>
                        </div>
                        <div className="row pt-5 mb-5 justify-content-center justify-content-lg-between">
                            <div className="col-md-6 col-lg-3">
                                <div className="text-center">
                                    <img src={require("../assets/img/solutionly-img1.png")} className="img-fluid" alt="why-solutionly" />
                                    <h6 className='mt-5'>Increase Business
                                        Growth</h6>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="text-center">
                                    <img src={require("../assets/img/solutionly-img2.png")} className="img-fluid" alt="why-solutionly" />
                                    <h6 className='mt-5'>Improved Brand
                                        Perception</h6>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="text-center">
                                    <img src={require("../assets/img/solutionly-img3.png")} className="img-fluid" alt="why-solutionly" />
                                    <h6 className='mt-5'>Enhanced User
                                        Satisfaction</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- contactus sec --> */}
            <section className="contactform-sec bg-white" id="contactus">
                <div className="container-fluid px-0 overflow-x-hidden">
                    <div className="desc-center">
                        <h2>Reach Us</h2>
                        <p>Let us know how we can assist you. Inquire about any digital strategy questions you've been curious about. Feel free to share your thoughts with us as we are dedicated to delivering the answers you seek.</p>
                    </div>
                    <div className="bg-dark py-5 px-4 px-md-0 mt-5">
                        <div className="row align-items-center px-0">
                            <div className="col-lg-6 px-0">
                                <form className="contact-form" name="contactform"  ref={form} onSubmit={handleSubmit(onSubmit)}>
                                    <h6 className="text-center text-violet fw-bold">Contact Us</h6>
                                    <div>
                                    {isSubmitted && errors.firstname && (
                                            <small role="alert" className='text-danger t-12'>{errors.firstname.message}</small>
                                        )}
                                        <input type="text" placeholder="Full Name*" autocomplete="off" {...register("firstname", { required: "First name is required" })} id="firstname"  onChange={changeInput} />
                                    </div>
                                    <div>
                                    {isSubmitted && errors.email && (
                                            <small role="alert" className='text-danger t-12'>{errors.email.message}</small>
                                        )}
                                        <input type="email" placeholder="Email ID*" autocomplete="off"  {...register("email", {
                                            required: "E-Mail is required", pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "invalid email address"
                                            }
                                        })} id="email"  onChange={changeInput} />
                                           
                                    </div>
                                    <div>
                                    {isSubmitted && errors.mobno && (
                                            <small role="alert" className='text-danger t-12'>{errors.mobno.message}</small>
                                        )}
                                        <input type="text" placeholder="Contact No*" autocomplete="off"  {...register("mobno", {
                                            required: "Contact No is required", pattern: {
                                                value:  /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/,
                                                message: "invalid contact number"

                                            }
                                            })} id="mobno" onChange={changeInput} />
                                              
                                    </div>
                                    <div>
                                    
                                        <input type="text" placeholder="Company*" autocomplete="off" {...register("company")} id="company" />
                                        
                                    </div>
                                    <div>
                                        <textarea type="text" id="description"  {...register("descrption")} placeholder="About your Project"></textarea>
                                    </div>
                                    <div className="d-flex align-item-center">
                                        <input type="checkbox" {...register("nda")} className="w-auto mb-0 me-3 text-violet" /><span className="fs-12">Request an NDA (Non Disclosure Agreement).</span>
                                    </div>
                                    <p className="text-violet fs-10">By sending this form I confirm that I have read and accept Solutionly <Link to="/privacy_policy">Privacy Policy</Link>.</p>
                                    <div className="text-center mt-2">
                                        {/* <button className="btn-1 g-recaptcha" 
                              data-sitekey="6LeKw58pAAAAAGWXFRCkDAkHgkZu3D8XwAf6jWO-" 
                              data-callback='onSubmit' 
                              data-action='submit' type="submit">Send</button>  */}
                                        <button className="btn-1 " onClick={()=> setIsSubmitted(true)} type="submit" disabled={isDisabled}>{"Send"}</button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-6 pt-5 pt-lg-0 px-0">
                                <div className="bg-white  content-box p-5">
                                    <h5 className="mb-5">What follows next?</h5>
                                    <ol className="ps-3">
                                        <li className="mb-4">Shortly after reviewing your business needs, our sales manager contacts you.</li>
                                        <li className="mb-4">During this time, we execute a non-disclosure agreement (NDA) to prioritize confidentiality.</li>
                                        <li className="mb-4">Following this, our pre-sales manager provides project estimates and an estimated timeline.</li>
                                        <li className="mb-4">Schedules a follow-up meeting to discuss any further details or adjustments.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
         
            <Footer />
        </>
    );
}

export default Home;
