import logo from '../assets/img/logo.png';
import '../App.css';
import { useEffect } from 'react';
import { Link , useLocation} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import $ from 'jquery'; 
function Header() {
const location = window.location.href.split("#")[1]
const pathName  = useLocation();
useEffect(()=>{
  console.log( pathName.pathname)
  console.log(pathName.hash)
// $(".checklocation").each(function() {
//     $(this).removeClass("active");
// });
// $(`#${location}-link`).addClass("active");
}, [pathName.hash])
  return (
   <header className="sticky-top" id='header'>
        <nav className="navbar navbar-expand-lg bg-purple">
            <div className="container-fluid align-items-center ms-lg-3">
              <Link className="navbar-brand" to="/">
                <img src={logo} width="200px" className="img-fluid" alt="logo" />
              </Link>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                    <HashLink className={`nav-link checklocation ${pathName.hash == "#aboutuss" ? 'active' : ""}`} to="/#aboutuss" id="aboutus-link">About us</HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink className={`nav-link checklocation ${location == "services" ? 'active' : ""}`} to="/#services" id='services-link'>Services</HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink className={`nav-link checklocation ${location == "industries" ? 'active' : ""}`} to="/#industries" id='industries-link'>Industries</HashLink>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link checklocation ${pathName.pathname == "/courses" ? 'active' : ""}`} to="/courses" id='industries-link'>Courses</Link>
                  </li>
                </ul>
                <HashLink className="btn-1 td-none btn-hover1" to="/#contactus">Contact Us</HashLink>
              </div>
            </div>
          </nav>
    </header>
    )
    }

    export default Header;