import React, { useEffect, useState } from "react";
import Header from "./header";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y, Autoplay } from 'swiper/modules';
import '../App.css';
import Footer from "./footer";
import { HashLink } from 'react-router-hash-link';

function Courses(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
        <div className='sticky-top'> <Header /></div>
        {/* banner starts */}
            <section className="banner-sec about-banner">
                <div className="containe-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <Swiper
                                // install Swiper modules
                                modules={[Navigation, A11y, Autoplay]}
                                spaceBetween={50}
                                slidesPerView={1}
                                autoplay={{
                                    delay: 8000,
                                    disableOnInteraction: false,
                                }}
                            >
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <div className="swiper-content swiper-content-75">
                                            <div className="ms-5">
                                                <h2 className='fw-light'>Ready to Build a Portfolio?</h2>
                                                <h3 className='mb-64'>Master UI/UX Through Practice, Not Just Theory - Transform Knowledge into Action!</h3>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <HashLink className="btn-1 td-none mt-3 ms-3 py-2" to="/#contactus">Enquire Now</HashLink>
                                {/* <a className="btn-1 td-none mt-3 ms-3 py-2" href="/#contactus">Enquire Now</a> */}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        {/* banner ends */}
        {/* 2nd section starts */}
        <section className="design-sec bg-grey" id="desc">
            <div className="container-fluid px-lg-0">
                <div className="px-lg-3  mx-4">  
                    <h1 className='mb-0 pb-0'>Knowledge is the foundation, but learning through <span className="text-violet">practice</span> builds mastery</h1>
                    
                </div>
            </div>
        </section>
        {/* 2nd section ends */}
        {/* 3rd section starts */}
        <section class="section">
            <div className="container-fluid px-lg-0">
                <div class="px-lg-3 mx-lg-4 section-wrapper position-relative">
                <div class="content">
                <h2>Practical UI/UX Design Course</h2>
                <ul>
                    <li>UI/UX Fundamentals</li>
                    <li>User Research & Design Thinking</li>
                    <li>Visuals Design & Prototyping</li>
                    <li>User Testing & Design Iterations</li>
                    <li>Case Study Development</li>
                    <li>Live & Interactive Session (Online)</li>
                </ul>
                <h2 class="bonus-heading">Bonus</h2>
                <ul>
                    <li>Resume & Interview Guidance</li>
                    <li>Certificate of Completion</li>
                    <li>Free After-Course Consultation</li>
                    <li>Small Batch Sizes - Personalized attention for every student</li>
                    <li>Capstone Project - Work on a complete end-to-end design project</li>
                    <li>Live Q&A Sessions to Resolve Queries in Real-Time</li>
                </ul>
                </div>
    <div class="image">
    <img src={require("../assets/img/practiceui-bannerimg.png")} className='img-fluid' alt='aboutus-img'/>
    </div>
                </div>
                <div class="text-center py-5 bordertop-grey">
                <HashLink className="btn-2 td-none" to="/#contactus">Reserve your Seat</HashLink>
                    {/* <a class="btn-2 td-none" href="/#contactus">Reserve your Seat</a> */}
                    </div>
            </div>
  </section>
  <Footer />
        </>
    )
}

export default Courses;