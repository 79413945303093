import './App.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import { BrowserRouter, Routes, Route, ScrollRestoration, HashRouter } from "react-router-dom";
import Home from './pages/home';
import PrivacyPolicy from './pages/privacy_policy';
import 'bootstrap/dist/css/bootstrap.css';
import About from './pages/aboutus'


function App() {
  return (
    <HashRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/courses" element={<About />} />
      <Route path="/privacy_policy" element={<PrivacyPolicy />} />
    </Routes>
  </HashRouter>
  );
}

export default App;
